<template>
  <v-container>

    <h1>{{$t('nAg-NGt02vNuZ1sIrHved')}}</h1>

    <v-expansion-panels
      class="py-10"
      accordion
      hover
      focusable
    >
      <v-expansion-panel
        v-for="(item,i) in questions"
        :key="i"
      >
        <v-expansion-panel-header>
          <strong>{{item.question}}</strong>
          <template v-slot:actions>
            <v-icon color="primary">mdi-menu-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="item.answer"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </v-container>
</template>

<script>

export default {
  name: 'HelpView',
  data () {
    return {
      questions: [
        {
          question: this.$t('p8P0Go3gSCr6YquJYGUTG'),
          answer: this.$t('s-kZP4y0W5ujU5F2r46jV')
        },
        {
          question: this.$t('SEYr-AkLJ25tIaqG8O8Cd'),
          answer: this.$t('TQDMRG-y8oV_PyCIqtwju')
        },
        {
          question: this.$t('OH8F_oadmbAD8TYzBK1K0'),
          answer: this.$t('Haw1Guvf6cGNRa-o3Y7nv')
        },
        {
          question: this.$t('xAramDOeClAFKgY7thEXX'),
          answer: this.$t('xIYz78a9fEpBSt1x0crwO')
        },
        {
          question: this.$t('IGfAHeS3e_EYIeS9Cv2Q-'),
          answer: this.$t('8hqKcuQ2QUAO0XcFHBj5k')
        },
        {
          question: this.$t('pv08Pbpa8jQILZcTCECo8'),
          answer: this.$t('_cZ8qC54oEwwnATpu_AUo')
        },
        {
          question: this.$t('UbPd2bpp0awqcME0dQxqi'),
          answer: this.$t('99tGQWM2QPoNaCiybcHkO')
        }

      ]
    }
  }
}
</script>
